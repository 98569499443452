<template>
  <div class="enterprise">
    <van-nav-bar
      title="停运审核"
      fixed
      left-arrow
      @click-left="goBack"
      placeholder
    >
    </van-nav-bar>
    <van-form :show-error="false">
      <van-cell-group title="基本信息">
        <van-cell title="企业名称" :value="formData.enterpriseName"> </van-cell>
        <van-cell title="站点名称" :value="formData.pointName"> </van-cell>
        <van-cell title="停运原因" :value="formData.context"> </van-cell>
        <van-cell title="申请附件">
          <div class="file">
            <div v-if="formData.fileList != ''">
              <div
                class="file-item"
                v-for="(file, index) in formData.fileList.filter((item) =>
                  /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.ossFileName)
                )"
                :key="index"
              >
                <img
                  class="image"
                  lazy-load
                  @click.stop="showPreview"
                  :src="
                    `${$api.base.file}/` + encodeURIComponent(file.ossFileName)
                  "
                />
              </div>
            </div>

            <div
              v-for="(items, index) in formData.fileList.filter((item) =>
                /\.(ppt|pptx|xlsx|xls|doc|pdf|docx)$/.test(item.ossFileName)
              )"
              :key="index"
            >
              <div @click="noticeFile(items)">
                {{ items.name }}
              </div>
            </div>
          </div>
        </van-cell>
        <van-cell title="停运时间" :value="startTime"> </van-cell>
        <van-field
          maxlength="200"
          v-model="formList.remark"
          label="备注"
          placeholder="备注"
          :colon="true"
          type="textarea"
        />
      </van-cell-group>
      <van-row style="margin-top: 16px">
        <van-col span="7" offset="4">
          <van-button round block type="info" @click="onSubmit(1)">
            同意
          </van-button>
        </van-col>
        <van-col span="7" offset="1">
          <van-button round block type="danger" @click="onSubmit(2)">
            驳回
          </van-button>
        </van-col>
      </van-row>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      startTime: "",
      formData: [],
      formList: {
        remark: "",
      },
      show: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getArcProductLineInfoById();
  },
  methods: {
    // 事件提醒
    noticeFile() {
      Toast.fail("不支持在线查看，请到PC端下载");
    },
    getArcProductLineInfoById() {
      const data = {
        id: this.$route.query.id,
      };
      this.$api.enterprise.getApplyInfo(data).then((res) => {
        if (res.data.state == 0) {
          this.formData = res.data.data;
          this.startTime =
            this.$moment(this.formData.startTime).format("YYYY-MM-DD") +
            "~" +
            this.$moment(this.formData.endTime).format("YYYY-MM-DD");
        }
      });
    },
    onSubmit(type) {
      const data = {
        bizId: this.$route.query.id,
        state: type,
        approvalOpinion: this.formList.remark,
      };
      this.$api.enterprise.getApplyApproval(data).then((res) => {
        if (res.data.state == "0") {
          if (type == 1) {
            Toast.success("审核成功!");
          } else {
            Toast.success("驳回成功!");
          }

          this.$router.push("/enterprise-stop-log");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
