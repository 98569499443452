var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"padding",rawName:"v-padding:top",value:(46),expression:"46",arg:"top"}],staticClass:"enterprise"},[_c('van-nav-bar',{attrs:{"title":"停运恢复申请","left-arrow":"","fixed":""},on:{"click-left":_vm.goLevel}}),[_c('van-list',{attrs:{"finished":_vm.dataFinished,"finished-text":"没有更多了"},on:{"load":_vm.getTableData},model:{value:(_vm.dataLoading),callback:function ($$v) {_vm.dataLoading=$$v},expression:"dataLoading"}},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"detail"},[_c('time-box',{attrs:{"value":item.gmtCreate}}),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"企业名称","value":item.enterpriseName}}),_c('van-cell',{attrs:{"title":"站点名称","value":item.poiName}}),_c('van-cell',{attrs:{"title":"停运原因","value":item.shutdownStartCause}}),_c('van-cell',{attrs:{"title":"申请附件"}},[_c('div',{staticClass:"file"},_vm._l((item.bizApplyFileList.filter((item) =>
                  /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.ossFileName)
                )),function(file,index){return _c('div',{key:index,staticClass:"file-item"},[_c('img',{staticClass:"image",attrs:{"lazy-load":"","src":`${_vm.$api.base.file}/` +
                    encodeURIComponent(file.ossFileName)},on:{"click":function($event){$event.stopPropagation();return _vm.showPreview.apply(null, arguments)}}})])}),0),_vm._l((item.bizApplyFileList.filter((item) =>
                /\.(ppt|pptx|xlsx|xls|doc|pdf|docx)$/.test(item.ossFileName)
              )),function(items,index){return _c('div',{key:index},[_c('div',{on:{"click":function($event){return _vm.noticeFile(items)}}},[_vm._v(" "+_vm._s(items.name)+" ")])])})],2),_c('van-cell',{attrs:{"title":"停运时间","value":`${_vm.$moment(item.shutdownStartTime).format(
              'YYYY-MM-DD'
            )} ~ ${_vm.$moment(item.shutdownEndTime).format('YYYY-MM-DD')}`}}),_c('van-cell',{attrs:{"title":""}},[_c('van-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleShow(item)}}},[_vm._v("恢复申请")])],1)],1)],1)}),0)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }