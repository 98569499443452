<template>
  <div class="time-box">{{ value }}</div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.time-box {
  padding: 9px 14px;
  font-size: 14px;
  color: #666666;
  text-align: right;
}
</style>
