var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"padding",rawName:"v-padding:top",value:(46),expression:"46",arg:"top"}],staticClass:"enterprise"},[_c('van-nav-bar',{attrs:{"title":"停运记录","left-arrow":"","fixed":""},on:{"click-left":function($event){return _vm.goBackTo()}}}),[_c('van-list',{attrs:{"finished":_vm.dataFinished,"finished-text":"没有更多了"},on:{"load":_vm.getTableData},model:{value:(_vm.dataLoading),callback:function ($$v) {_vm.dataLoading=$$v},expression:"dataLoading"}},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"detail"},[_c('time-box',{attrs:{"value":item.gmtCreate}}),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"站点名称","value":item.pointName}}),_c('van-cell',{attrs:{"title":"停运时间","value":`${_vm.$moment(item.startTime).format(
              'YYYY-MM-DD'
            )} ~ ${_vm.$moment(item.endTime).format('YYYY-MM-DD')}`}}),_c('van-cell',{attrs:{"title":"停运原因","value":item.context}}),_c('van-cell',{attrs:{"title":"申请人","value":item.applyUserName}}),_c('van-cell',{attrs:{"title":"申请时间","value":item.gmtCreate}}),_c('van-cell',{attrs:{"title":"申请附件"}},[_c('div',{staticClass:"file"},_vm._l((item.fileList.filter((item) =>
                  /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.ossFileName)
                )),function(file,index){return _c('div',{key:index,staticClass:"file-item"},[_c('img',{staticClass:"image",attrs:{"lazy-load":"","src":`${_vm.$api.base.file}/` +
                    encodeURIComponent(file.ossFileName)},on:{"click":function($event){$event.stopPropagation();return _vm.showPreview.apply(null, arguments)}}})])}),0),_vm._l((item.fileList.filter((item) =>
                /\.(ppt|pptx|xlsx|xls|doc|pdf|docx)$/.test(item.ossFileName)
              )),function(items,index){return _c('div',{key:index},[_c('div',{on:{"click":function($event){return _vm.noticeFile(items)}}},[_vm._v(" "+_vm._s(items.name)+" ")])])})],2),_c('van-cell',{attrs:{"title":"处理状态"}},[(item.state == 0)?_c('van-tag',{attrs:{"type":"primary"}},[_vm._v("审核中")]):_vm._e(),(item.state == 1)?_c('van-tag',{attrs:{"type":"success"}},[_vm._v("审核通过")]):_vm._e(),(item.state == 2)?_c('van-tag',{attrs:{"type":"danger"}},[_vm._v("审核不通过")]):_vm._e()],1),_c('van-cell',{attrs:{"title":"审批人","value":`${item.approvalUserName ? item.approvalUserName : '-'}`}}),_c('van-cell',{attrs:{"title":"审批时间","value":`${item.approvalTime ? item.approvalTime : '-'}`}}),(_vm.userType == 1 && _vm.roleId == 3 && item.state == 0)?_c('van-cell',{attrs:{"title":""}},[_c('van-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleShow(item)}}},[_vm._v("申请处理")])],1):_vm._e()],1)],1)}),0)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }