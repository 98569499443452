<template>
  <div class="enterprise" v-padding:top="46">
    <!-- 头部 -->
    <van-nav-bar title="企业" left-arrow @click-left="goBack" fixed>
      <van-icon
        name="iconfont iconfont iconrilirichengCRM"
        size="18"
        slot="right"
        @click="onShowPopup"
      />
    </van-nav-bar>
    <!-- 头部end -->
    <!-- 搜索区域 -->
    <div class="search">
      <van-tabs type="card" v-model="tabName">
        <van-tab title="监测数据" name="data"></van-tab>
        <van-tab title="超标数据" name="overproof"></van-tab>
        <!-- <van-tab title="仪表参数" name="parameter"></van-tab>
        <van-tab title="仪表状态" name="state"></van-tab> -->
      </van-tabs>
    </div>
    <!-- 搜索区域end -->

    <!-- 站点信息 -->
    <van-cell-group>
      <van-cell :label="poiName">
        <span class="cell-title" slot="title">{{ enterpriseName }}</span>
      </van-cell>
    </van-cell-group>
    <!-- 站点信息end -->
    <!-- 时间类型 -->
    <van-tabs v-model="timeType" v-if="tabName == 'data'">
      <van-tab title="实时" :name="1"></van-tab>
      <van-tab title="分钟" :name="2"></van-tab>
      <van-tab title="小时" :name="3"></van-tab>
      <van-tab title="日" :name="4"></van-tab>
    </van-tabs>
    <!-- 时间类型end -->
    <!-- 监测数据 -->
    <template v-if="tabName == 'data'">
      <van-list
        v-model="dataLoading"
        :finished="dataFinished"
        finished-text="没有更多了"
        @load="getDataData"
      >
        <div v-for="(item, index) in dataList" :key="index">
          <time-box :value="item.dataTime"></time-box>
          <van-cell-group
            v-for="(titleItem, titleIndex) in dataTitle"
            :key="titleIndex"
          >
            <van-cell
              :title="titleItem.title"
              :value="item[titleItem.field] ? item[titleItem.field].rtd : '-'"
            >
            </van-cell>
          </van-cell-group>
        </div>
      </van-list>
    </template>
    <!--超标数据 -->
    <template v-if="tabName == 'overproof'">
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="factorName"
          :options="factorList"
          @change="onFactorChange"
        />
        <van-dropdown-item
          v-model="type"
          :options="typeList"
          @change="onTypeChange"
        />
      </van-dropdown-menu>

      <table class="exceed" v-if="exceedData.length > 0">
        <thead>
          <tr>
            <th>因子名称</th>
            <th>预警时间</th>
            <th>超标下限值</th>
            <th>超标上限值</th>
            <th>超标因子值</th>
          </tr>
        </thead>
        <template>
          <tbody v-for="(item, index) in exceedData" :key="index">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.gmt_modified }}</td>
              <td>{{ item.floorval }}</td>
              <td>{{ item.ceilval }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </template>
      </table>
      <van-empty description="暂无数据" v-else />
    </template>
    <!-- 监测数据end -->
    <!-- 仪表参数 -->
    <template v-if="tabName == 'parameter'">
      <van-list
        v-model="parameterLoading"
        :finished="parameterFinished"
        finished-text="没有更多了"
        @load="getParameterData"
      >
        <div v-for="(item, index) in parameterList" :key="index">
          <time-box :value="item.dataTime"></time-box>
          <van-cell-group>
            <van-cell
              title="设备"
              :value="item.deviceName ? item.deviceName : '-'"
            >
            </van-cell>
            <van-cell
              title="污染物"
              :value="item.divisorName ? item.divisorName : '-'"
            >
            </van-cell>
            <van-cell
              title="参数"
              :value="item.paramName ? item.paramName : '-'"
            >
            </van-cell>
            <van-cell
              title="校准值上限"
              :value="item.warningCeiling ? item.warningCeiling : '-'"
            >
            </van-cell>
            <van-cell
              title="校准值下限"
              :value="item.warningFloor ? item.warningFloor : '-'"
            >
            </van-cell>
            <van-cell
              title="修改后"
              :value-class="
                (item.value > item.warningCeiling && item.warningCeiling) ||
                (item.value < item.warningFloor && item.warningFloor)
                  ? 'red'
                  : ''
              "
              :value="item.value ? item.value : '-'"
            >
            </van-cell>
          </van-cell-group>
        </div>
      </van-list>
    </template>
    <!-- 仪表参数end -->
    <!-- 仪表状态 -->
    <template v-if="tabName == 'state'">
      <van-list
        v-model="stateLoading"
        :finished="stateFinished"
        finished-text="没有更多了"
        @load="getStateData"
      >
        <div v-for="(item, index) in stateList" :key="index">
          <time-box :value="item.dataTime"></time-box>
          <van-cell-group>
            <van-cell title="设备" :value="item.deviceName"> </van-cell>
            <van-cell title="污染物" :value="item.divisorName"> </van-cell>
            <van-cell title="当前状态">
              <template>
                <van-tag type="success" v-if="item.value == '0'">运行</van-tag>
                <!-- <van-tag type="danger" v-if="item.value == '1'">维护</van-tag>
                <van-tag type="danger" v-if="item.value == '2'">故障</van-tag>
                <van-tag type="danger" v-if="item.value == '3'">校准</van-tag>
                <van-tag type="danger" v-if="item.value == '5'">反吹</van-tag>
                <van-tag type="danger" v-if="item.value == '6'"
                  >电源故障</van-tag
                >
                <van-tag type="danger" v-if="item.value == '7'">测量</van-tag>
                <van-tag type="danger" v-if="item.value == '8'">标定</van-tag>
                <van-tag type="danger" v-if="item.value == '9'">待机</van-tag>
                <van-tag type="danger" v-if="item.value == '10'">运维</van-tag> -->
                <van-tag type="danger" v-if="item.value">{{
                  statusList[item.value]
                }}</van-tag>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </van-list>
    </template>
    <!-- 仪表状态end -->
    <!-- 搜索弹出层 -->
    <van-popup v-model="popupShow" position="right" class="search-popup">
      <div class="search">
        <van-field
          :value="tabName == 'data' ? dateValue : timeValue"
          placeholder="时间范围"
          @click="onShowCalendar"
          disabled
        >
          <van-icon
            name="iconfont iconfont iconrilirichengCRM"
            slot="right-icon"
            @click="onShowCalendar"
          />
        </van-field>
      </div>
      <van-row class="fix-bottom">
        <van-col span="24"
          ><van-button type="primary" block @click="define"
            >确定</van-button
          ></van-col
        >
      </van-row>
    </van-popup>
    <!-- 搜索弹出层end -->
    <!-- 日历弹层 -->
    <van-calendar
      type="range"
      color="#378aed"
      close-on-popstate
      v-model="showCalendar"
      :max-range="tabName == 'data' ? 3 : ''"
      range-prompt="监测数据查询范围不能超过三天"
      title="时间范围"
      :allow-same-day="true"
      :min-date="minData"
      @confirm="onConfirm"
      :default-date="tabName == 'data' ? range : timeRange"
    />
    <!-- 日历弹层end -->
  </div>
</template>

<script>
import { Toast } from "vant";
import timeBox from "@/components/common/time-box.vue"; // 时间显示框

export default {
  components: { timeBox },
  data() {
    return {
      statusList: {
        1: "维护",
        2: "故障",
        3: "校准",
        5: "反吹",
        6: "电源故障",
        7: "测量",
        8: "标定",
        9: "待机",
        10: "运维",
      },
      minData: new Date("2020/01/01"),
      poiId: "", // 站点id
      poiName: "", // 站点名称
      enterpriseName: "", // 企业名称
      beginTime:
        this.$moment().subtract(1, "days").format("YYYY-MM-DD") + " 00:00:00", // 开始时间
      endTime: this.$moment().format("YYYY-MM-DD") + " 23:59:59", // 结束时间
      dateValue:
        this.$moment().subtract(1, "days").format("YYYY-MM-DD") +
        " ~ " +
        this.$moment().format("YYYY-MM-DD"), // 时间
      timeValue:
        this.$moment().subtract(6, "days").format("YYYY-MM-DD") +
        " ~ " +
        this.$moment().format("YYYY-MM-DD"), // 时间
      showCalendar: false, // 显示日历
      popupShow: false, // 显示弹层
      tabName: "data", // tab名称
      timeType: 1, // 时间类型
      dataTitle: [], // 监测数据title
      dataList: [], // 监测数据列表
      exceedData: [], // 超标数据列表
      factorShow: false, // 因子弹出
      factorName: "", // 因子名称
      type: 2061,
      factorList: [{ text: "全部因子", value: "" }], // 因子列表
      typeList: [
        { text: "小时数据", value: 2061 },
        { text: "日数据", value: 2031 },
      ], // 数据类型
      overproofLoading: false, // 监测数据加载
      overproofFinished: false, // 监测数据完结
      dataLoading: false, // 监测数据加载
      dataFinished: false, // 监测数据完结
      parameterList: [], // 仪表参数列表
      parameterLoading: false, // 仪表参数加载
      parameterFinished: false, // 仪表参数数据完结
      stateList: [], // 仪表状态列表
      stateLoading: false, // 仪表状态加载
      stateFinished: false, // 仪表状态数据完结
      dataCurrent: 1, // 监测数据页码
      overproofCurrent: 1, // 超标数据页码
      parameterCurrent: 1, // 仪表参数页码
      stateCurrent: 1, // 仪表状态页码
      pagesize: 10, // 单页数据量
      poiTypeId: "", // 站点类型
      range: [
        new Date(
          this.$moment().subtract(1, "days").format("YYYY/MM/DD") + " 00:00:00"
        ),
        new Date(this.$moment().format("YYYY/MM/DD") + " 23:59:59"),
      ],
      timeRange: [
        new Date(
          this.$moment().subtract(6, "days").format("YYYY/MM/DD") + " 00:00:00"
        ),
        new Date(this.$moment().format("YYYY/MM/DD") + " 23:59:59"),
      ],
    };
  },
  watch: {
    tabName() {
      if (this.tabName == "data") {
        this.beginTime =
          this.$moment().subtract(1, "days").format("YYYY-MM-DD") + " 00:00:00"; // 开始时间
        this.endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59"; // 结束时间
        if (
          this.$moment(this.endTime).valueOf() -
            this.$moment(this.beginTime).valueOf() >
          4 * 24 * 60 * 60 * 1000
        ) {
          Toast("监测数据查询范围不能超过三天");
          this.endTime =
            this.$moment(this.beginTime).add(3, "days").format("YYYY-MM-DD") +
            " 23:59:59";
          this.dateValue =
            this.$moment(this.beginTime).format("YYYY-MM-DD") +
            " ~ " +
            this.$moment(this.endTime).format("YYYY-MM-DD");
        }
      } else if (this.tabName == "overproof") {
        this.beginTime =
          this.$moment().subtract(6, "days").format("YYYY-MM-DD") + " 00:00:00"; // 开始时间
        this.endTime = this.$moment().format("YYYY-MM-DD") + " 23:59:59"; // 结束时间
        this.timeValue =
          this.$moment(this.beginTime).format("YYYY-MM-DD") +
          " ~ " +
          this.$moment(this.endTime).format("YYYY-MM-DD");
      }
      this.onSearch();
      // this.getFactorName();
      // this.getOverproofData();
    },
    timeType() {
      this.dataCurrent = 1;
      this.dataList = [];
      this.getDataData();
    },
  },
  methods: {
    // 监测数据查询
    onDataSearch() {
      this.getDataTitle();
      this.getDataData();
    },
    // 获取监测数据title
    getDataTitle() {
      const data = {
        id: this.poiId,
        cn: this.timeType,
      };
      this.$api.enterprise
        .getDataTitleByPointId(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.dataTitle = res.data.data.filter(
              (item) => item.field != "dataTime"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取监测数据数据
    getDataData() {
      this.dataLoading = true;
      const data = {
        pointId: this.poiId,
        timeType: this.timeType,
        index: this.dataCurrent,
        size: this.pagesize,
        beginTime: this.beginTime,
        endTime: this.endTime,
      };
      this.$api.enterprise
        .getDataData(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.dataList.push(...res.data.data.records);
            if (res.data.data.pages == undefined || res.data.data.pages == []) {
              this.dataFinished = true;
            } else if (this.dataCurrent >= res.data.data.pages) {
              this.dataFinished = true;
            } else {
              this.dataCurrent++;
            }
            // if (this.dataCurrent >= res.data.data.pages) {
            //   this.dataFinished = true;
            // } else {
            //   this.dataCurrent++;
            // }
            this.dataLoading = false;
          }
        })
        .catch((err) => {
          this.dataFinished = true;
          console.log(err);
        });
    },
    // 仪表参数查询
    onParameterSearch() {
      this.getParameterData();
    },
    // 仪表状态查询
    onStateSearch() {
      this.getStateData();
    },
    // 查询当日超标详情
    // getExcessiveDetail() {
    //   const data = {
    //     eventId: this.$route.query.id
    //   };
    //   this.$api.event.getEventExcessiveList(data).then(res => {
    //     if (res.data.state == 0) {
    //       this.exceedData = res.data.data || [];
    //     }
    //   });
    // },
    // 获取仪表参数数据
    getParameterData() {
      this.parameterLoading = true;
      const data = {
        pointId: this.poiId,
        index: this.parameterCurrent,
        size: this.pagesize,
        beginTime: this.beginTime,
        endTime: this.endTime,
      };
      this.$api.enterprise
        .getParameterData(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.parameterList.push(...res.data.data.records);
            if (this.parameterCurrent >= res.data.data.pages) {
              this.parameterFinished = true;
            } else {
              this.parameterCurrent++;
            }
            this.parameterLoading = false;
          }
        })
        .catch((err) => {
          this.parameterFinished = true;
          console.log(err);
        });
    },
    // 获取超标数据
    getOverproofData() {
      const data = {
        pointId: this.poiId,
        divisorName: this.factorName,
        type: this.type,
        beginTime: this.beginTime,
        endTime: this.endTime,
      };
      this.$api.enterprise
        .getOverproofData(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.exceedData = res.data.data || [];
          }
        })
        .catch((err) => {
          this.stateFinished = true;
          console.log(err);
        });
    },
    onTypeChange(detail) {
      this.type = detail;
      this.getFactorName();
      this.getOverproofData();
    },
    onFactorChange(detail) {
      this.factorName = detail;
      this.getOverproofData();
    },
    // 获取因子名称
    getFactorName() {
      const data = {
        pointId: this.poiId,
        beginTime: this.beginTime,
        endTime: this.endTime,
        type: this.type,
      };
      this.$api.enterprise
        .getFactorData(data)
        .then((res) => {
          if (res.data.state == 0) {
            const divisorList = res.data.data;
            if (divisorList != "") {
              const array = [];
              for (let i = 0; i < divisorList.length; i++) {
                this.factorList = [{ text: "全部因子", value: "" }];
                const item = {};
                item["text"] = divisorList[i];
                item["value"] = divisorList[i];
                array.push(item);
              }
              this.factorList = this.factorList.concat(array);
              const divisor = this.factorList.filter((item) => {
                if (item.value == this.factorName) {
                  return item;
                }
              });

              if (divisor.length <= 0) {
                this.factorName = "";
              }
            } else {
              this.factorList = [{ text: "全部因子", value: "" }];
              this.factorName = "";
            }
          }
        })
        .catch((err) => {
          this.stateFinished = true;
          console.log(err);
        });
    },
    // 获取仪表状态参数
    getStateData() {
      this.stateLoading = true;
      const data = {
        index: this.stateCurrent,
        size: this.pagesize,
        pointId: this.poiId,
        beginTime: this.beginTime,
        endTime: this.endTime,
      };
      this.$api.enterprise
        .getStateData(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.stateList.push(...res.data.data.records);
            if (this.stateCurrent >= res.data.data.pages) {
              this.stateFinished = true;
            } else {
              this.stateCurrent++;
            }
          }
        })
        .catch((err) => {
          this.stateFinished = true;
          console.log(err);
        });
    },
    // 搜索
    onSearch() {
      if (this.tabName == "data") {
        this.dataCurrent = 1;
        this.dataTitle = [];
        this.dataList = [];
        this.dataFinished = false;
        this.onDataSearch();
      }
      if (this.tabName == "parameter") {
        this.parameterCurrent = 1;
        this.parameterList = [];
        this.parameterFinished = false;
        this.onParameterSearch();
      }
      if (this.tabName == "state") {
        this.stateCurrent = 1;
        this.stateList = [];
        this.stateFinished = false;
        this.onStateSearch();
      }
      if (this.tabName == "overproof") {
        // 气类站点只有小时数据
        // if (this.poiTypeId == 31) {
        //   this.typeList = [{ text: "小时数据", value: 2061 }];
        // } else if (this.poiTypeId == 32) {
        //   this.typeList = [
        //     { text: "小时数据", value: 2061 },
        //     { text: "日数据", value: 2031 },
        //   ];
        // }
        this.typeList = [
          { text: "小时数据", value: 2061 },
          { text: "日数据", value: 2031 },
        ];
        this.stateFinished = false;
        this.exceedData = [];
        this.getFactorName();
        this.getOverproofData();
      }
    },
    // 设置站点id
    setPoiId() {
      this.poiId = this.$route.query.id;
    },
    // 设置企业名称
    setEnterpriseName() {
      this.enterpriseName = this.$route.query.enterpriseName;
    },
    // 设置站点名称
    setPoiName() {
      this.poiName = this.$route.query.poiName;
    },
    // 设置站点类型
    setPoiTypeId() {
      this.poiTypeId = this.$route.query.poiTypeId;
    },
    // 日历确定事件
    onConfirm(date) {
      this.beginTime = this.$moment(date[0]).format("YYYY-MM-DD") + " 00:00:00";
      this.endTime = this.$moment(date[1]).format("YYYY-MM-DD") + " 23:59:59";
      this.dateValue =
        this.$moment(date[0]).format("YYYY-MM-DD") +
        " ~ " +
        this.$moment(date[1]).format("YYYY-MM-DD");
      this.timeValue =
        this.$moment(date[0]).format("YYYY-MM-DD") +
        " ~ " +
        this.$moment(date[1]).format("YYYY-MM-DD");
      this.showCalendar = false;
    },
    // 显示日历
    onShowCalendar() {
      this.showCalendar = true;
    },
    // 显示弹出层
    onShowPopup() {
      this.popupShow = true;
    },
    // 确定
    define() {
      this.onSearch();
      this.popupShow = false;
    },
  },
  created() {
    this.setPoiId();
    this.setEnterpriseName();
    this.setPoiName();
    this.setPoiTypeId();
    this.onSearch();
  },
};
</script>

<style></style>
