<template>
  <div class="enterprise" v-padding:top="46">
    <!-- 头部 -->
    <van-nav-bar title="企业" fixed v-if="userType == 2">
      <template #left>
        <van-icon
          name="iconfont iconfont icontingyun"
          size="20"
          @click="onSheetShow(1)"
        />
      </template>
    </van-nav-bar>
    <van-nav-bar title="企业" fixed v-if="userType == 1 && roleId == 3">
      <template #left>
        <van-icon
          name="iconfont iconfont icontingyun"
          size="20"
          @click="onSheetShow(2)"
        />
      </template>
    </van-nav-bar>
    <van-nav-bar
      title="企业"
      fixed
      v-if="userType == 3 || (userType == 1 && roleId == 1)"
    >
      <!-- <template #left>
        <van-icon
          name="iconfont iconfont icontingyun"
          size="20"
          @click="onSheetShow(1)"
        />
      </template> -->

      <!-- <template #right>
        <van-icon name="scan" size="20" @click="scanQRCode" />
      </template> -->
    </van-nav-bar>
    <!-- 搜索区域 -->
    <van-sticky :offset-top="46">
      <div class="search">
        <van-field
          v-model="enterpriseName"
          placeholder="搜索企业名称"
          right-icon="search"
          @click-right-icon="onSearch"
        />
      </div>
    </van-sticky>
    <!-- 站点列表区域 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多数据了"
      @load="getPointList"
    >
      <van-cell-group v-for="(item, index) in pointList" :key="index">
        <van-cell
          :label="item.poiName"
          is-link
          @click="toEnterpriseDetail(item)"
        >
          <span class="cell-title" slot="title">{{ item.enterpriseName }}</span>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 动作面板 -->
    <van-action-sheet
      v-model="sheetShow"
      :actions="type == 1 ? sheetActions : examineAction"
      @select="onSheetSelect"
      cancel-text="取消"
      close-on-click-action
      @cancel="onSheetCancel"
    />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Dialog } from "vant";

export default {
  data() {
    return {
      sheetShow: false,
      sheetActions: [
        { name: "停运申请", type: 0 },
        { name: "停运申请记录", type: 1 },
        { name: "停运恢复申请", type: 2 },
        { name: "停运恢复申请记录", type: 3 },
      ],
      examineAction: [
        { name: "停运审核记录", type: 1 },
        { name: "停运恢复审核记录", type: 3 },
      ],
      pointList: [], // 站点列表
      loading: false, // 上拉加载loading
      finished: false, // 上拉加载完结loading
      current: 1, // 页码
      pagesize: 10, // 单页条数
      enterpriseName: "", // 企业名称,
      type: "",
    };
  },
  computed: {
    userType() {
      // 1 环保局用户 2企业用户 3运维用户
      return localStorage.getItem("userType");
    },
    roleId() {
      return localStorage.getItem("roleId");
    },
  },
  methods: {
    // 面板显示事件
    onSheetShow(type) {
      this.type = type;
      this.sheetShow = true;
    },
    // 面板关闭事件
    onSheetCancel() {
      this.sheetShow = false;
    },
    // 面板选中事件
    onSheetSelect(item) {
      if (item.type == 0) {
        this.onStopRunning();
      } else if (item.type == 1) {
        this.onStopLog();
      } else if (item.type == 2) {
        this.onApplication();
      } else if (item.type == 3) {
        this.onApplicationLog();
      } else if (item.type == 4) {
        this.onApplicationLog();
      }
    },
    // 停运记录
    onStopLog() {
      this.$router.push({
        name: "EnterpriseStopLog",
      });
    },
    // 停运
    onStopRunning() {
      this.$router.push({
        name: "EnterpriseStop",
      });
    },
    // 停运申请恢复
    onApplication() {
      this.$router.push({
        name: "EnterpriseStopApplication",
      });
    },
    // 停运申请恢复记录
    onApplicationLog() {
      this.$router.push({
        name: "EnterpriseShutdownRecord",
      });
    },
    // 停运恢复审核记录
    // onOutageRecovery(){

    // },

    // 扫描二维码
    scanQRCode() {
      const _this = this;
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success(res) {
          const data = JSON.parse(res.resultStr);
          // 判断二维码类型
          if (data.type == 1) {
            // 类型1 门禁二维码
            console.log(data.type);
          } else if (data.type == 2) {
            // 类型2 运维二维码
            if (data.sn != "") {
              const _data = {
                userId: localStorage.getItem("userId"),
                sn: data.sn,
              };
              _this.$api.operation.getPointMtn(_data).then((params) => {
                if (params.data.state == 0) {
                  _this.$router.push({
                    name: "TableOperationSelect",
                    query: {
                      sn: data.sn,
                      enterpriseName: params.data.data.enterpriseName,
                      pointName: params.data.data.pointName,
                    },
                  });
                }
              });
            } else {
              Dialog({ message: "该仪器未配置SN号" });
            }
          } else {
            Dialog({ message: "无法识别" });
          }
        },
      });
    },
    // 跳转企业详情页面
    toEnterpriseDetail(item) {
      this.$router.push({
        name: "EnterpriseDetail",
        query: {
          id: item.id,
          enterpriseName: item.enterpriseName,
          poiName: item.poiName,
          poiTypeId: item.poiTypeId,
        },
      });
    },
    // 查询
    onSearch() {
      this.current = 1;
      this.pointList = [];
      this.getPointList();
    },
    // 获取站点列表
    getPointList() {
      this.loading = true;
      const data = {
        index: this.current,
        size: this.pagesize,
        enterpriseName: this.enterpriseName,
      };
      this.$api.enterprise
        .getPointList(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.pointList.push(...res.data.data.records);
            if (this.current >= res.data.data.pages) {
              this.finished = true;
            } else {
              this.current++;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.getPointList();
    // this.getSdkConfig(["scanQRCode"]); //获取微信SDK配置
  },
};
</script>

<style lang="less" scoped></style>
