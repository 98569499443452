<template>
  <div class="enterprise">
    <van-nav-bar
      title="停运恢复申请"
      fixed
      left-arrow
      @click-left="goBack"
      placeholder
    >
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group title="基本信息">
        <!-- <van-field
          readonly
          clickable
          name="picker"
          v-model="formList.name"
          label="停运站点:"
          placeholder="请选择停运站点"
          @click="isShowAction = true"
          :rules="[{ required: true, message: '请选择停运站点' }]"
        />
        <van-field
          v-model="date"
          label="停运日期:"
          placeholder="请选择停运日期"
          @click="show = true"
          :rules="[{ required: true, message: '请选择停运日期' }]"
        >
        </van-field>
        <van-field
          v-model="formList.context"
          label="停运原因"
          placeholder="请输入停运原因"
          :colon="true"
          :rules="[{ required: true, message: '请输入停运原因' }]"
          type="textarea"
        /> -->
        <van-field
          v-model="date"
          label="恢复时间:"
          placeholder="请选择恢复时间"
          @click="show = true"
          :rules="[{ required: true, message: '请选择恢复时间' }]"
        >
        </van-field>
        <van-field
          maxlength="200"
          v-model="formList.context"
          label="恢复原因"
          placeholder="请输入恢复原因"
          :colon="true"
          :rules="[{ required: true, message: '请输入恢复原因' }]"
          type="textarea"
        />
        <van-field name="uploader" label="恢复申请报告">
          <template #input>
            <van-uploader
              v-model="file"
              :max-count="3"
              :after-read="afterRead"
              :before-read="beforeRead"
              @delete="deleteFile"
            />
          </template>
        </van-field>
      </van-cell-group>
      <van-row style="margin: 16px" id="item">
        <van-col span="24">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-action-sheet
      title="请选择站点名称"
      :close-on-click-action="true"
      v-model="isShowAction"
      :actions="actions"
      @select="onActionSelect"
      :style="{ height: '62%' }"
    />
    <van-calendar
      v-model="show"
      type="single"
      @confirm="onConfirm"
      :min-date="minDate"
      :max-date="maxDate"
      :default-date="defaultDate"
      :allow-same-day="true"
      :style="{ height: '62%' }"
      color="#378aed"
    />
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      maxDate: new Date(this.$moment(this.$route.query.shutdownEndTime)),
      actions: [],
      value: "",
      isShowAction: false,
      formList: {
        name: "",
        id: "",
        context: "",
        stoptime: "",
        poiName: "",
        startTime:
          this.$moment().add(1, "days").format("YYYY-MM-DD") + " 00:00:00", // 开始时间
        endTime: this.$moment().format("YYYY-MM-DD") + " 23:59:59", // 结束时间,
        // bizId: ""
        // type: 1
      },
      enterpriseList: [],
      pointList: [],
      showPicker: false,
      date: "",
      show: false,
      minDate: new Date(this.$moment().add(1, "days")),
      defaultDate: this.$moment().add(1, "days").toDate(),
      // this.$moment()
      //   .add(2, "days")
      //   .toDate()
      file: [],
      uploadedFile: [],
      fileType: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    deleteFile(file, detail) {
      file.status = "uploading";
      const { index } = detail;
      const fileId = this.uploadedFile[index];
      const typeId = this.fileType[index];
      const data = {
        id: fileId,
      };
      this.$api.common.getDeleteFile(data).then((res) => {
        if (res.data.state == 0) {
          this.uploadedFile = this.uploadedFile.filter((item) => {
            return item != fileId;
          });
          this.fileType = this.fileType.filter((item) => {
            return item != typeId;
          });
          file.status = "done";
        }
      });
    },
    beforeRead(file) {
      const flag = new RegExp(
        "[`~!@#$^&*=|{}':;',\\[\\]<>《》/?~！@#￥……&*——|{}【】‘；：”“'。，、？%]"
      );
      if (flag.test(file.name)) {
        Toast.fail({
          message: "文件名不能包含特殊字符",
        });
        return false;
      }
      return true;
    },
    afterRead(file) {
      if (file.length > 1) {
        file.forEach((item) => {
          this.fileType = this.fileType.concat(item.file.name);
        });
      } else {
        this.fileType = this.fileType.concat(file.file.name);
      }
      file.status = "uploading";
      const data = new FormData();
      data.append("file", file.file);

      this.$api.common.uploadFileRecoverApi(data).then((res) => {
        this.uploadedFile = this.uploadedFile.concat(res.data.data[0].id);
        file.status = "done";
      });
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.$moment(date).format("YYYY-MM-DD") + " 00:00:00";
    },
    // 动作面板选择
    onActionSelect(action) {
      this.formList.name = action.name;
      this.formList.id = action.value;
    },
    // 显示弹出层
    showAction() {
      this.isShowAction = true;
    },

    onSubmit() {
      const data = {
        bizId: this.$route.query.id,
        recoverTime: this.date,
        context: this.formList.context,
        type: 3,
        fileIdList: this.uploadedFile,
        applyUser: localStorage.getItem("userId"),
        // state: 0,
        // mode: 0
      };
      this.$api.enterprise.getRecoverApplication(data).then((res) => {
        if (res.data.state == "0") {
          Toast.success("新增成功!");
          this.$router.push("/enterprise-stop-application");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
