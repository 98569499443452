<template>
  <div class="enterprise" v-padding:top="46">
    <!-- 头部 -->
    <van-nav-bar title="停运恢复申请" left-arrow @click-left="goLevel" fixed>
    </van-nav-bar>
    <!-- 头部end -->
    <!-- 监测数据 -->
    <template>
      <van-list
        v-model="dataLoading"
        :finished="dataFinished"
        finished-text="没有更多了"
        @load="getTableData"
      >
        <div v-for="(item, index) in dataList" :key="index" class="detail">
          <time-box :value="item.gmtCreate"></time-box>
          <van-cell-group>
            <van-cell title="企业名称" :value="item.enterpriseName"> </van-cell>
            <van-cell title="站点名称" :value="item.poiName"> </van-cell>
            <van-cell title="停运原因" :value="item.shutdownStartCause">
            </van-cell>
            <van-cell title="申请附件">
              <div class="file">
                <div
                  class="file-item"
                  v-for="(file, index) in item.bizApplyFileList.filter((item) =>
                    /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.ossFileName)
                  )"
                  :key="index"
                >
                  <img
                    class="image"
                    lazy-load
                    @click.stop="showPreview"
                    :src="
                      `${$api.base.file}/` +
                      encodeURIComponent(file.ossFileName)
                    "
                  />
                </div>
              </div>

              <div
                v-for="(items, index) in item.bizApplyFileList.filter((item) =>
                  /\.(ppt|pptx|xlsx|xls|doc|pdf|docx)$/.test(item.ossFileName)
                )"
                :key="index"
              >
                <div @click="noticeFile(items)">
                  {{ items.name }}
                </div>
              </div>
            </van-cell>
            <van-cell
              title="停运时间"
              :value="`${$moment(item.shutdownStartTime).format(
                'YYYY-MM-DD'
              )} ~ ${$moment(item.shutdownEndTime).format('YYYY-MM-DD')}`"
            >
            </van-cell>
            <van-cell title="">
              <van-button type="primary" size="mini" @click="handleShow(item)"
                >恢复申请</van-button
              >
            </van-cell>
          </van-cell-group>
        </div>
      </van-list>
    </template>
    <!-- 监测数据end -->
  </div>
</template>

<script>
import { Toast } from "vant";
import timeBox from "@/components/common/time-box.vue"; // 时间显示框

export default {
  components: { timeBox },
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      dataList: [], // 监测数据列表
      dataLoading: false, // 监测数据加载
      dataFinished: false, // 监测数据完结
      current: 1, // 监测数据页码
      pagesize: 10, // 单页数据量
    };
  },
  computed: {
    userType() {
      // 1 环保局用户 2企业用户 3运维用户
      return localStorage.getItem("userType");
    },
    roleId() {
      return localStorage.getItem("roleId");
    },
  },
  methods: {
    // 事件提醒
    noticeFile() {
      Toast.fail("不支持在线查看，请到PC端下载");
    },
    goLevel() {
      this.$router.push({
        name: "Enterprise",
      });
    },
    handleShow(item) {
      this.$router.push({
        name: "EnterpriseApplicationLog",
        query: {
          id: item.id,
          shutdownEndTime: item.shutdownEndTime,
        },
      });
    },
    // 搜索
    onSearch() {
      this.getTableData();
    },
    // 获取表格数据
    getTableData() {
      this.dataLoading = true;
      const data = {
        index: this.current,
        size: this.pagesize,
        enterpriseId: this.enterpriseId,
      };

      this.$api.enterprise
        .getRecoverData(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.dataList.push(...res.data.data.records);
            if (this.current >= res.data.data.pages) {
              this.dataFinished = true;
            } else {
              this.current++;
            }
            this.dataLoading = false;
          }
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  created() {
    this.onSearch();
  },
};
</script>

<style lang="less" scoped>
.detail {
  .file {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: "";
      width: 33%;
    }
    .file-item {
      width: 33%;
      height: auto;
    }
  }

  .image {
    width: 55px;
    height: 60px;
    margin-bottom: 10px;
  }
}
</style>
